import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "PasswordStrength",
  props: {
    password: {
      type: String,
      default: ""
    },
    tips: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      strength: 0
    };
  },
  watch: {
    password: {
      handler: function handler(newVal) {
        if (!newVal) {
          this.strength = 0;
          this.$emit("on-change", this.strength);
          return;
        } else if (newVal.length < 12) {
          this.strength = 1;
          this.$emit("on-change", this.strength);
          return;
        }
        ;
        var strength = 0;
        var regExpList = [/[0-9]/, /[a-z]/, /[A-Z]/, /[@#￥!^&*()]/];
        regExpList.forEach(function (regExp) {
          if (regExp.test(newVal)) {
            strength++;
          }
        });
        this.strength = strength;
        this.$emit("on-change", this.strength);
      }
    }
  }
};