// const TokenKey = 'vue_admin_template_token'
var TokenKey = 'Authorization';
export function getToken() {
  return localStorage.getItem(TokenKey);
}
export function setToken(token) {
  return localStorage.setItem(TokenKey, token);
}
export function removeToken() {
  return localStorage.removeItem(TokenKey);
}