







































export default {
  name: "searchForm",
  props: {
    // 是否使用单选
    isShowReset: {
      type: Boolean,
      default: true
    },
    isRowBtn: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    queryData() {
      this.$emit("queryData");
    },
    resetQueryParams() {
      this.$emit("resetQueryParams");
    }
  }
};
