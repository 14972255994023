//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "App",
  data: function data() {
    return {
      // 页面loading展示
      isShowLoading: false,
      // loading文字
      loadingText: "拼命加载中",
      // loading icon
      loadingIcon: "el-icon-loading"
    };
  },
  mounted: function mounted() {
    var _this = this;
    console.log('version', '1.0.2');
    document.getElementById("app").style.display = "block";
    document.getElementById("appLoading").style.display = "none";
    this.$bus.$on("showLoading", function (val) {
      _this.isShowLoading = val.status;
      _this.loadingText = val.text || "加载中...";
      _this.loadingIcon = val.icon || "el-icon-loading";
    });
  }
};