/*
 * @Author: your name
 * @Date: 2022-01-20 16:22:18
 * @LastEditTime: 2022-01-20 16:22:43
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \CarlsbergOrderAutoManagementFront\src\components\masterTableList\apo.js
 */
import request from "@/utils/request";
export function getTableByPage(method, url, data, params) {
  return request({
    url: url,
    method: method,
    data: data,
    params: params
  });
}