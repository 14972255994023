import _Notification2 from "element-ui/lib/theme-chalk/notification.css";
import "element-ui/lib/theme-chalk/base.css";
import _Notification from "element-ui/lib/notification";
import _MessageBox2 from "element-ui/lib/theme-chalk/message-box.css";
import "element-ui/lib/theme-chalk/base.css";
import _MessageBox from "element-ui/lib/message-box";
import _Message2 from "element-ui/lib/theme-chalk/message.css";
import "element-ui/lib/theme-chalk/base.css";
import _Message from "element-ui/lib/message";
import _Loading2 from "element-ui/lib/theme-chalk/loading.css";
import "element-ui/lib/theme-chalk/base.css";
import _Loading from "element-ui/lib/loading";
import _Transfer2 from "element-ui/lib/theme-chalk/transfer.css";
import "element-ui/lib/theme-chalk/base.css";
import _Transfer from "element-ui/lib/transfer";
import _Step2 from "element-ui/lib/theme-chalk/step.css";
import "element-ui/lib/theme-chalk/base.css";
import _Step from "element-ui/lib/step";
import _Steps2 from "element-ui/lib/theme-chalk/steps.css";
import "element-ui/lib/theme-chalk/base.css";
import _Steps from "element-ui/lib/steps";
import _Avatar2 from "element-ui/lib/theme-chalk/avatar.css";
import "element-ui/lib/theme-chalk/base.css";
import _Avatar from "element-ui/lib/avatar";
import _CarouselItem2 from "element-ui/lib/theme-chalk/carousel-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _CarouselItem from "element-ui/lib/carousel-item";
import _Carousel2 from "element-ui/lib/theme-chalk/carousel.css";
import "element-ui/lib/theme-chalk/base.css";
import _Carousel from "element-ui/lib/carousel";
import _Scrollbar2 from "element-ui/lib/theme-chalk/scrollbar.css";
import "element-ui/lib/theme-chalk/base.css";
import _Scrollbar from "element-ui/lib/scrollbar";
import _Cascader2 from "element-ui/lib/theme-chalk/cascader.css";
import "element-ui/lib/theme-chalk/base.css";
import _Cascader from "element-ui/lib/cascader";
import _Image2 from "element-ui/lib/theme-chalk/image.css";
import "element-ui/lib/theme-chalk/base.css";
import _Image from "element-ui/lib/image";
import _CollapseItem2 from "element-ui/lib/theme-chalk/collapse-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _CollapseItem from "element-ui/lib/collapse-item";
import _Collapse2 from "element-ui/lib/theme-chalk/collapse.css";
import "element-ui/lib/theme-chalk/base.css";
import _Collapse from "element-ui/lib/collapse";
import _ColorPicker2 from "element-ui/lib/theme-chalk/color-picker.css";
import "element-ui/lib/theme-chalk/base.css";
import _ColorPicker from "element-ui/lib/color-picker";
import _Popover2 from "element-ui/lib/theme-chalk/popover.css";
import "element-ui/lib/theme-chalk/base.css";
import _Popover from "element-ui/lib/popover";
import _Tree2 from "element-ui/lib/theme-chalk/tree.css";
import "element-ui/lib/theme-chalk/base.css";
import _Tree from "element-ui/lib/tree";
import _Divider2 from "element-ui/lib/theme-chalk/divider.css";
import "element-ui/lib/theme-chalk/base.css";
import _Divider from "element-ui/lib/divider";
import _Card2 from "element-ui/lib/theme-chalk/card.css";
import "element-ui/lib/theme-chalk/base.css";
import _Card from "element-ui/lib/card";
import _Progress2 from "element-ui/lib/theme-chalk/progress.css";
import "element-ui/lib/theme-chalk/base.css";
import _Progress from "element-ui/lib/progress";
import _Upload2 from "element-ui/lib/theme-chalk/upload.css";
import "element-ui/lib/theme-chalk/base.css";
import _Upload from "element-ui/lib/upload";
import _Col2 from "element-ui/lib/theme-chalk/col.css";
import "element-ui/lib/theme-chalk/base.css";
import _Col from "element-ui/lib/col";
import _Row2 from "element-ui/lib/theme-chalk/row.css";
import "element-ui/lib/theme-chalk/base.css";
import _Row from "element-ui/lib/row";
import _Icon2 from "element-ui/lib/theme-chalk/icon.css";
import "element-ui/lib/theme-chalk/base.css";
import _Icon from "element-ui/lib/icon";
import _Alert2 from "element-ui/lib/theme-chalk/alert.css";
import "element-ui/lib/theme-chalk/base.css";
import _Alert from "element-ui/lib/alert";
import _Tag2 from "element-ui/lib/theme-chalk/tag.css";
import "element-ui/lib/theme-chalk/base.css";
import _Tag from "element-ui/lib/tag";
import _TabPane2 from "element-ui/lib/theme-chalk/tab-pane.css";
import "element-ui/lib/theme-chalk/base.css";
import _TabPane from "element-ui/lib/tab-pane";
import _Tabs2 from "element-ui/lib/theme-chalk/tabs.css";
import "element-ui/lib/theme-chalk/base.css";
import _Tabs from "element-ui/lib/tabs";
import _FormItem2 from "element-ui/lib/theme-chalk/form-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _FormItem from "element-ui/lib/form-item";
import _Form2 from "element-ui/lib/theme-chalk/form.css";
import "element-ui/lib/theme-chalk/base.css";
import _Form from "element-ui/lib/form";
import _BreadcrumbItem2 from "element-ui/lib/theme-chalk/breadcrumb-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _BreadcrumbItem from "element-ui/lib/breadcrumb-item";
import _Breadcrumb2 from "element-ui/lib/theme-chalk/breadcrumb.css";
import "element-ui/lib/theme-chalk/base.css";
import _Breadcrumb from "element-ui/lib/breadcrumb";
import _Tooltip2 from "element-ui/lib/theme-chalk/tooltip.css";
import "element-ui/lib/theme-chalk/base.css";
import _Tooltip from "element-ui/lib/tooltip";
import _TimePicker2 from "element-ui/lib/theme-chalk/time-picker.css";
import "element-ui/lib/theme-chalk/base.css";
import _TimePicker from "element-ui/lib/time-picker";
import _DatePicker2 from "element-ui/lib/theme-chalk/date-picker.css";
import "element-ui/lib/theme-chalk/base.css";
import _DatePicker from "element-ui/lib/date-picker";
import _TableColumn2 from "element-ui/lib/theme-chalk/table-column.css";
import "element-ui/lib/theme-chalk/base.css";
import _TableColumn from "element-ui/lib/table-column";
import _Table2 from "element-ui/lib/theme-chalk/table.css";
import "element-ui/lib/theme-chalk/base.css";
import _Table from "element-ui/lib/table";
import _Button2 from "element-ui/lib/theme-chalk/button.css";
import "element-ui/lib/theme-chalk/base.css";
import _Button from "element-ui/lib/button";
import _OptionGroup2 from "element-ui/lib/theme-chalk/option-group.css";
import "element-ui/lib/theme-chalk/base.css";
import _OptionGroup from "element-ui/lib/option-group";
import _Option2 from "element-ui/lib/theme-chalk/option.css";
import "element-ui/lib/theme-chalk/base.css";
import _Option from "element-ui/lib/option";
import _Badge2 from "element-ui/lib/theme-chalk/badge.css";
import "element-ui/lib/theme-chalk/base.css";
import _Badge from "element-ui/lib/badge";
import _Select2 from "element-ui/lib/theme-chalk/select.css";
import "element-ui/lib/theme-chalk/base.css";
import _Select from "element-ui/lib/select";
import _Switch2 from "element-ui/lib/theme-chalk/switch.css";
import "element-ui/lib/theme-chalk/base.css";
import _Switch from "element-ui/lib/switch";
import _CheckboxGroup2 from "element-ui/lib/theme-chalk/checkbox-group.css";
import "element-ui/lib/theme-chalk/base.css";
import _CheckboxGroup from "element-ui/lib/checkbox-group";
import _CheckboxButton2 from "element-ui/lib/theme-chalk/checkbox-button.css";
import "element-ui/lib/theme-chalk/base.css";
import _CheckboxButton from "element-ui/lib/checkbox-button";
import _Checkbox2 from "element-ui/lib/theme-chalk/checkbox.css";
import "element-ui/lib/theme-chalk/base.css";
import _Checkbox from "element-ui/lib/checkbox";
import _RadioButton2 from "element-ui/lib/theme-chalk/radio-button.css";
import "element-ui/lib/theme-chalk/base.css";
import _RadioButton from "element-ui/lib/radio-button";
import _RadioGroup2 from "element-ui/lib/theme-chalk/radio-group.css";
import "element-ui/lib/theme-chalk/base.css";
import _RadioGroup from "element-ui/lib/radio-group";
import _Radio2 from "element-ui/lib/theme-chalk/radio.css";
import "element-ui/lib/theme-chalk/base.css";
import _Radio from "element-ui/lib/radio";
import _InputNumber2 from "element-ui/lib/theme-chalk/input-number.css";
import "element-ui/lib/theme-chalk/base.css";
import _InputNumber from "element-ui/lib/input-number";
import _Input2 from "element-ui/lib/theme-chalk/input.css";
import "element-ui/lib/theme-chalk/base.css";
import _Input from "element-ui/lib/input";
import _MenuItem2 from "element-ui/lib/theme-chalk/menu-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _MenuItem from "element-ui/lib/menu-item";
import _Submenu2 from "element-ui/lib/theme-chalk/submenu.css";
import "element-ui/lib/theme-chalk/base.css";
import _Submenu from "element-ui/lib/submenu";
import _Menu2 from "element-ui/lib/theme-chalk/menu.css";
import "element-ui/lib/theme-chalk/base.css";
import _Menu from "element-ui/lib/menu";
import _DropdownItem2 from "element-ui/lib/theme-chalk/dropdown-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _DropdownItem from "element-ui/lib/dropdown-item";
import _DropdownMenu2 from "element-ui/lib/theme-chalk/dropdown-menu.css";
import "element-ui/lib/theme-chalk/base.css";
import _DropdownMenu from "element-ui/lib/dropdown-menu";
import _Dropdown2 from "element-ui/lib/theme-chalk/dropdown.css";
import "element-ui/lib/theme-chalk/base.css";
import _Dropdown from "element-ui/lib/dropdown";
import _Dialog2 from "element-ui/lib/theme-chalk/dialog.css";
import "element-ui/lib/theme-chalk/base.css";
import _Dialog from "element-ui/lib/dialog";
import _Pagination2 from "element-ui/lib/theme-chalk/pagination.css";
import "element-ui/lib/theme-chalk/base.css";
import _Pagination from "element-ui/lib/pagination";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/tmp/workspace/bcrm/oso-platform-front/ci_bcrm_oso-platform-front/src_code/node_modules/core-js/modules/es6.array.iterator.js";
import "/tmp/workspace/bcrm/oso-platform-front/ci_bcrm_oso-platform-front/src_code/node_modules/core-js/modules/es6.promise.js";
import "/tmp/workspace/bcrm/oso-platform-front/ci_bcrm_oso-platform-front/src_code/node_modules/core-js/modules/es6.object.assign.js";
import "/tmp/workspace/bcrm/oso-platform-front/ci_bcrm_oso-platform-front/src_code/node_modules/core-js/modules/es7.promise.finally.js";
// update 10-09 16:05

import Vue from 'vue';
import 'normalize.css/normalize.css';
import "./styles/element-variables.scss";
import Contextmenu from "vue-contextmenujs";

/*自定义表格的样式*/
import { tableHeaderStyle, tableHeaderStyleChildren, rowStyle, rowStyleChild, getRowClass } from '@/utils/pub-function';
Vue.prototype.tableHeaderStyle = tableHeaderStyle;
Vue.prototype.tableHeaderStyleChildren = tableHeaderStyleChildren;
Vue.prototype.rowStyle = rowStyle;
Vue.prototype.rowStyleChild = rowStyleChild;
Vue.prototype.getRowClass = getRowClass;
Vue.prototype.$bus = new Vue();
Vue.prototype.dayjs = require('dayjs');
import pubTools from "./utils/pub-tools";
Vue.prototype.EncryptFn = pubTools.EncryptFn;
Vue.prototype.DecryptFn = pubTools.DecryptFn;
import '@/styles/index.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import '@/icons'; // icon
import '@/permission'; // permission control
import * as filters from '@/utils/filters';
import utilsTool from '@/utils/utilsTool.js';
Vue.use(utilsTool);
import directive from "./directive.js";
// 注册全局组件
import GL_Component from '@/components/globalComponents.js';
Vue.use(GL_Component);
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.use(Contextmenu);
Vue.use(_Pagination);
Vue.use(_Dialog);
Vue.use(_Dropdown);
Vue.use(_DropdownMenu);
Vue.use(_DropdownItem);
Vue.use(_Menu);
Vue.use(_Submenu);
Vue.use(_MenuItem);
Vue.use(_Input);
Vue.use(_InputNumber);
Vue.use(_Radio);
Vue.use(_RadioGroup);
Vue.use(_RadioButton);
Vue.use(_Checkbox);
Vue.use(_CheckboxButton);
Vue.use(_CheckboxGroup);
Vue.use(_Switch);
Vue.use(_Select);
Vue.use(_Badge);
Vue.use(_Option);
Vue.use(_OptionGroup);
Vue.use(_Button);
Vue.use(_Table);
Vue.use(_TableColumn);
Vue.use(_DatePicker);
Vue.use(_TimePicker);
Vue.use(_Tooltip);
Vue.use(_Breadcrumb);
Vue.use(_BreadcrumbItem);
Vue.use(_Form);
Vue.use(_FormItem);
Vue.use(_Tabs);
Vue.use(_TabPane);
Vue.use(_Tag);
Vue.use(_Alert);
Vue.use(_Icon);
Vue.use(_Row);
Vue.use(_Col);
Vue.use(_Upload);
Vue.use(_Progress);
Vue.use(_Card);
Vue.use(_Divider);
Vue.use(_Tree);
Vue.use(_Popover);
Vue.use(_ColorPicker);
Vue.use(_Collapse);
Vue.use(_CollapseItem);
Vue.use(_Image);
Vue.use(_Cascader);
Vue.use(_Scrollbar);
Vue.use(_Carousel);
Vue.use(_CarouselItem);
Vue.use(_Avatar);
Vue.use(_Steps);
Vue.use(_Step);
Vue.use(_Transfer);
Vue.use(_Loading.directive);
Vue.prototype.$loading = _Loading.service;
Vue.prototype.$message = _Message;
Vue.prototype.$messagebox = _MessageBox;
Vue.prototype.$notify = _Notification;
Vue.use(directive);
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});