import Layout from "@/layout";
export default {
  // 主数据管理
  path: "/master-data-dealer",
  name: "masterData",
  component: Layout,
  redirect: "/master-data-dealer/dealer-info",
  meta: {
    type: 0,
    only: true,
    title: "经销商主数据管理",
    icon: "el-icon-lock",
    permission: "customer.master.manage"
  },
  children: [{
    path: "dealer-info",
    name: "dealerInfo",
    component: function component() {
      return import(/* webpackChunkName: "dealer-detail" */'@/views/masterDataManage/dealersList/detail.vue');
    },
    meta: {
      title: "经销商基本信息",
      permission: "customer.info"
    }
  }, {
    path: "dealer-product-list",
    name: "dealerProductList",
    component: function component() {
      return import(/* webpackChunkName: "dealer-product-list" */"@/views/masterDataManage/dealersProductList/list.vue");
    },
    meta: {
      title: "经销商产品列表",
      permission: "dms.sku.manage"
    }
  }, {
    path: "dealer-product-detail",
    name: "dealerProductDetail",
    component: function component() {
      return import(/* webpackChunkName: "dealer-product-detail" */"@/views/masterDataManage/dealersProductList/create.vue");
    },
    meta: {
      title: "经销商产品详情",
      permission: "customer.master.manage"
    },
    hidden: true
  }, {
    path: "dealer-store-list",
    name: "dealerStoreList",
    component: function component() {
      return import(/* webpackChunkName: "dealer-store-list" */"@/views/masterDataManage/dealersStoreList/index.vue");
    },
    meta: {
      title: "经销商覆盖门店列表",
      permission: "customer.shop.manage"
    }
  }]
};