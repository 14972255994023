import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "searchLabel",
  props: {
    // 是否使用单选
    label: {
      type: [String, undefined],
      default: undefined
    },
    border: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    labelWidth: {
      type: [String, Number],
      default: 50
    },
    colon: {
      type: Boolean,
      default: false
    },
    labelStyle: {
      type: Object,
      default: function _default() {}
    }
  }
};