var state = {
  theme: "#427781",
  colorPrimary: "",
  colorHover: ""
};
var mutations = {
  changeTheme: function changeTheme(state, step) {
    //修改主题
    state.theme = step;
  },
  readLocalTheme: function readLocalTheme(state) {
    //读取本地主题色
    if (localStorage.getItem('theme') && localStorage.getItem('theme').length) {
      state.theme = localStorage.getItem('theme');
    } else {
      document.getElementsByTagName("body")[0].style.setProperty("--color-primary", "#F5222D");
      document.getElementsByTagName("body")[0].style.setProperty("--color-hover", "#fee9ea");
      sessionStorage.setItem("color-primary", "#F5222D");
      sessionStorage.setItem("color-hover", "#fee9ea");
      state.colorPrimary = "#F5222D";
      state.colorHover = "#fee9ea";
    }
  }
};
var actions = {};
export default {
  namespaced: true,
  //用于解决不同模块命名冲突的问题。设置为true时，使用模块中的mutations、getters、actions时候，要加上模块名。
  state: state,
  mutations: mutations,
  actions: actions
};