import Layout from "@/layout";
export default {
  path: '/article',
  name: 'article',
  component: Layout,
  redirect: '/article/article-info',
  meta: {
    type: 0,
    only: true,
    title: '文章管理',
    icon: 'el-icon-lock',
    permission: "article.manage"
  },
  children: [{
    path: 'article-info',
    name: 'articleInfo',
    component: function component() {
      return import(/* webpackChunkName: "article" */'@/views/article/articleInfo/index.vue');
    },
    meta: {
      title: '文章列表',
      permission: 'article.info'
    }
  }, {
    path: 'article-create',
    name: 'articleInfo',
    hidden: true,
    component: function component() {
      return import(/* webpackChunkName: "article" */'@/views/article/articleInfo/create.vue');
    },
    meta: {
      title: '添加文章',
      permission: 'article.info'
    }
  }, {
    path: 'slideshow-info',
    component: function component() {
      return import(/* webpackChunkName: "article" */'@/views/article/slideshowInfo/index.vue');
    },
    name: 'slideshowInfo',
    meta: {
      title: '首页轮播',
      permission: 'slideshow.info'
    }
  }, {
    path: 'slideshow-create',
    component: function component() {
      return import(/* webpackChunkName: "article" */'@/views/article/slideshowInfo/create.vue');
    },
    name: 'slideshowInfo',
    hidden: true,
    meta: {
      title: '首页轮播新增',
      permission: 'slideshow.info'
    }
  }, {
    path: 'homepage-config',
    component: function component() {
      return import(/* webpackChunkName: "article" */'@/views/article/homepageConfig/index.vue');
    },
    name: 'homepageConfig',
    hidden: false,
    meta: {
      title: '首页排序配置',
      permission: 'slideshow.info'
    }
  }]
};