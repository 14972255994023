import Layout from "@/layout";
export default {
  path: '/integral',
  name: 'integral',
  component: Layout,
  redirect: '/integral/user-integral',
  meta: {
    type: 0,
    only: true,
    title: '奖励管理',
    icon: 'el-icon-lock',
    permission: "integral.manage"
  },
  children: [{
    path: 'user-integral',
    name: 'userIntegral',
    component: function component() {
      return import(/* webpackChunkName: "integral" */'@/views/integral/userIntegral/index.vue');
    },
    meta: {
      title: '用户积分',
      permission: 'user.integral'
    }
  }, {
    path: 'integral-details',
    name: 'integralDetails',
    hidden: true,
    component: function component() {
      return import(/* webpackChunkName: "integral" */'@/views/integral/userIntegral/details.vue');
    },
    meta: {
      title: '用户积分详情',
      permission: 'user.integral'
    }
  }, {
    path: 'integral-detail',
    component: function component() {
      return import(/* webpackChunkName: "integral" */'@/views/integral/integralDetail/index.vue');
    },
    name: 'integralDetail',
    meta: {
      title: '积分明细',
      permission: 'integral.detail'
    }
  }, {
    path: 'integral-freeze',
    component: function component() {
      return import(/* webpackChunkName: "integral" */'@/views/integral/integralFreeze/index.vue');
    },
    name: 'integralFreeze',
    meta: {
      title: '积分冻结',
      permission: 'integral.freeze'
    }
  }, {
    path: 'integral-rule',
    component: function component() {
      return import(/* webpackChunkName: "integral" */'@/views/integral/integralRule/index.vue');
    },
    name: 'integralRule',
    meta: {
      title: '积分规则配置',
      permission: 'integral.rule'
    }
  }, {
    path: 'virtual-reward',
    component: function component() {
      return import(/* webpackChunkName: "integral" */'@/views/integral/virtualReward/index.vue');
    },
    name: 'virtualReward',
    meta: {
      title: '虚拟领取情况',
      permission: 'award.virtual'
    }
  }, {
    path: 'physical-reward',
    component: function component() {
      return import(/* webpackChunkName: "integral" */'@/views/integral/physicalReward/index.vue');
    },
    name: 'physicalReward',
    meta: {
      title: '实物领取情况',
      permission: 'award.physical'
    }
  }]
};