import Layout from "@/layout";
export default {
  path: '/data-board',
  name: 'dataBoard',
  component: Layout,
  redirect: '/data-board/integral',
  meta: {
    type: 0,
    only: true,
    title: '数据看板',
    icon: 'el-icon-lock',
    permission: "data.board"
  },
  children: [{
    path: 'integral',
    name: 'integralBoard',
    component: function component() {
      return import('@/views/dataBoard/integralBoard/index.vue');
    },
    meta: {
      title: '积分看板',
      type: 0,
      permission: 'integral.borad'
    }
  }, {
    path: 'verification',
    name: 'verificationBoard',
    component: function component() {
      return import('@/views/dataBoard/verificationBoard/index.vue');
    },
    meta: {
      title: '核销看板',
      type: 0,
      permission: 'ticket.board'
    }
  }, {
    path: 'registerVerificationRecord',
    name: 'registerVerificationRecord',
    component: function component() {
      return import('@/views/dataBoard/registerVerificationRecord/index.vue');
    },
    meta: {
      title: '登记核销记录',
      type: 0,
      permission: 'record.form.list'
    }
  }]
};