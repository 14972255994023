/*
 * @Author: 张中玉
 * @Date: 2021-06-28 14:27:57
 * @LastEditors: 张中玉
 * @LastEditTime: 2021-08-19 15:33:03
 * @Description:
 */
var state = {
  // 订单列表 订单状态筛选
  liListData: [{
    name: "预订单",
    className: "li_color",
    status: null
  }, {
    name: "待确认",
    className: "",
    status: '001'
  }, {
    name: "确认中",
    className: "",
    status: '004'
  }, {
    name: "待审核",
    className: "",
    status: '005'
  }, {
    name: "待支付",
    className: "",
    status: '301'
  }, {
    name: "待发货",
    className: "",
    status: '101'
  }, {
    name: "待签收",
    className: "",
    status: '103'
  }, {
    name: "已完成",
    className: "",
    status: '104'
  }, {
    name: "已取消",
    className: "",
    status: '002'
  },
  // { name: "已退货", className: "", status: '107' },
  // { name: "已换货", className: "", status: '106' },
  {
    name: "确认失败",
    className: "",
    status: '003,201,202'
  }],
  liWarningListData: [{
    name: "所有",
    className: "li_color",
    status: 4
  }, {
    name: "高级预警",
    className: "",
    status: 1
  }, {
    name: "中级预警",
    className: "",
    status: 2
  }, {
    name: "普通订单",
    className: "",
    status: 3
  }],
  // 线程
  threadOptions: [{
    value: null,
    label: "所有"
  }, {
    value: 1,
    label: "单程"
  }, {
    value: 2,
    label: "双程"
  }],
  // 配送方式
  goodsOptions: [{
    value: null,
    label: "所有"
  }, {
    value: 1,
    label: "自提"
  }, {
    value: 2,
    label: "配送"
  }],
  // 是否应用促销政策
  promotionOptions: [{
    value: null,
    label: "所有"
  }, {
    value: 1,
    label: "非促销"
  }, {
    value: 2,
    label: "促销"
  }],
  // 是否紧急订单
  orderType: [{
    value: null,
    label: '所有'
  }, {
    value: 1,
    label: '普通订单'
  }, {
    value: 2,
    label: '紧急订单'
  }],
  // 单据类型数据
  billOptions: [{
    value: 2,
    label: "经销商报单"
  }, {
    value: 1,
    label: "销售报单"
  }],
  // 渠道
  channelOptions: [{
    value: 2,
    label: "餐饮"
  }, {
    value: 3,
    label: "传统"
  }, {
    value: 4,
    label: "娱乐"
  }, {
    value: 1,
    label: "商超"
  }],
  // 创建订单的 选择线程
  threadOptionsCreate: [{
    value: 1,
    label: "单程"
  }, {
    value: 2,
    label: "双程"
  }],
  // 回瓶方式
  bottleReturnTypeOptions: [{
    value: 1,
    label: "麻袋"
  }, {
    value: 2,
    label: "纸箱"
  }, {
    value: 3,
    label: "托盘"
  }, {
    value: 4,
    label: "塑箱"
  }],
  // 回瓶运输方式
  bottleTransportationOptions: [{
    value: 1,
    label: "自提"
  }, {
    value: 2,
    label: "配送"
  }]
};
var mutations = {
  /**
   * @Description: 重置li数据源
   * @author ZZY
   * @date 2021/7/27
  */
  RESET_LIST_DATA: function RESET_LIST_DATA(state) {
    state.liListData = [{
      name: "预订单",
      className: "li_color",
      status: null
    }, {
      name: "待确认",
      className: "",
      status: '001'
    }, {
      name: "确认中",
      className: "",
      status: '004'
    }, {
      name: "待审核",
      className: "",
      status: '005'
    }, {
      name: "待支付",
      className: "",
      status: '301'
    }, {
      name: "待发货",
      className: "",
      status: '101'
    }, {
      name: "待签收",
      className: "",
      status: '103'
    }, {
      name: "已完成",
      className: "",
      status: '104'
    }, {
      name: "已取消",
      className: "",
      status: '002'
    },
    // { name: "已退货", className: "", status: '107' },
    // { name: "已换货", className: "", status: '106' },
    {
      name: "确认失败",
      className: "",
      status: '003,201,202'
    }];
  }
};
var actions = {
  resetLIListData: function resetLIListData(_ref) {
    var commit = _ref.commit;
    commit('RESET_LIST_DATA');
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};