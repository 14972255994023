var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isShowLoading,
          expression: "isShowLoading",
        },
      ],
      attrs: {
        id: "app",
        "element-loading-text": _vm.loadingText,
        "element-loading-spinner": _vm.loadingIcon,
        "element-loading-background": "rgba(0, 0, 0, 0.8)",
      },
    },
    [_c("router-view")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }