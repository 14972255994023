import _defineProperty from "/tmp/workspace/bcrm/oso-platform-front/ci_bcrm_oso-platform-front/src_code/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import Layout from "@/layout";
export default {
  // 主数据管理
  path: '/master-data-admin',
  name: 'masterData',
  component: Layout,
  redirect: '/master-data-admin/dealer-list',
  meta: {
    type: 0,
    only: true,
    title: '经销商主数据管理',
    icon: 'el-icon-lock',
    permission: "customer.list"
  },
  children: [{
    path: 'dealer-list',
    name: 'dealerList',
    component: function component() {
      return import(/* webpackChunkName: "dealer-list" */'@/views/masterDataManage/dealersList/index.vue');
    },
    meta: {
      title: '经销商列表',
      permission: 'customer.list'
    }
  }, {
    hidden: true,
    path: 'dealer-detail',
    name: 'dealerDetail',
    component: function component() {
      return import(/* webpackChunkName: "dealer-detail" */'@/views/masterDataManage/dealersList/detail.vue');
    },
    meta: {
      title: '经销商详情',
      permission: 'customer.list'
    }
  }, {
    path: "dealer-product-detail",
    name: "dealerProductDetail",
    component: function component() {
      return import(/* webpackChunkName: "dealer-product-detail" */"@/views/masterDataManage/dealersProductList/create.vue");
    },
    meta: {
      title: "经销商产品详情",
      permission: "activity.manage"
    },
    hidden: true
  }, {
    path: "dealer-product-list",
    name: "dealerProductList",
    component: function component() {
      return import(/* webpackChunkName: "dealer-product-list" */"@/views/masterDataManage/dealersProductList/list.vue");
    },
    meta: {
      title: "经销商产品列表",
      permission: "activity.manage"
    },
    hidden: true
  }, {
    path: "dealer-store-list",
    name: "dealerStoreList",
    component: function component() {
      return import(/* webpackChunkName: "dealer-store-list" */"@/views/masterDataManage/dealersStoreList/index.vue");
    },
    meta: {
      title: "经销商覆盖门店列表",
      permission: "activity.manage"
    },
    hidden: true
  }, {
    path: 'first-purchase-list',
    name: 'firstPurchaseList',
    component: function component() {
      return import(/* webpackChunkName: "first-purchase-list" */'@/views/promotionPolicyManage/firstPurchaseList/index.vue');
    },
    meta: {
      title: '首件优惠策略列表',
      permission: 'activity.manage'
    },
    hidden: true
  }, _defineProperty({
    path: 'first-purchase-create',
    name: 'firstPurchaseCreate',
    hidden: true,
    component: function component() {
      return import(/* webpackChunkName: "first-purchase-create" */'@/views/promotionPolicyManage/firstPurchaseList/create.vue');
    },
    meta: {
      title: '首件优惠策略维护',
      permission: 'activity.manage'
    }
  }, "hidden", true), {
    path: 'reach-number-list',
    name: 'reachNumberList',
    component: function component() {
      return import(/* webpackChunkName: "reach-number-list" */'@/views/promotionPolicyManage/reachNumberList/index.vue');
    },
    meta: {
      title: '达量优惠策略列表',
      permission: 'activity.manage'
    },
    hidden: true
  }, _defineProperty({
    path: 'reach-number-create',
    name: 'reachNumberCreate',
    hidden: true,
    component: function component() {
      return import(/* webpackChunkName: "reach-number-create" */'@/views/promotionPolicyManage/reachNumberList/create.vue');
    },
    meta: {
      title: '达量优惠策略维护',
      permission: 'activity.manage'
    }
  }, "hidden", true), {
    path: 'reach-giving-list',
    name: 'reachGivingList',
    component: function component() {
      return import(/* webpackChunkName: "reach-giving-list" */'@/views/promotionPolicyManage/reachGivingList/index.vue');
    },
    meta: {
      title: '满减优惠策略列表',
      permission: 'activity.manage'
    },
    hidden: true
  }, _defineProperty({
    path: 'reach-giving-create',
    name: 'reachGivingCreate',
    hidden: true,
    component: function component() {
      return import(/* webpackChunkName: "reach-giving-create" */'@/views/promotionPolicyManage/reachGivingList/create.vue');
    },
    meta: {
      title: '满减优惠策略维护',
      permission: 'activity.manage'
    }
  }, "hidden", true)]
};