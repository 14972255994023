import Layout from "@/layout";
export default {
  // 经销商促销政策管理
  path: '/promotion-policy-manage',
  name: 'promotionPolicyManage',
  component: Layout,
  redirect: '/promotion-policy-manage/first-purchase-list',
  meta: {
    type: 0,
    only: true,
    title: '经销商促销政策管理',
    icon: 'el-icon-lock',
    permission: "discount.policy.manage"
  },
  children: [{
    path: 'first-purchase-list',
    name: 'firstPurchaseList',
    component: function component() {
      return import(/* webpackChunkName: "first-purchase-list" */'@/views/promotionPolicyManage/firstPurchaseList/index.vue');
    },
    meta: {
      title: '首件优惠策略列表',
      permission: 'discount.policy.manage'
    }
  }, {
    path: 'first-purchase-create',
    name: 'firstPurchaseCreate',
    hidden: true,
    component: function component() {
      return import(/* webpackChunkName: "first-purchase-create" */'@/views/promotionPolicyManage/firstPurchaseList/create.vue');
    },
    meta: {
      title: '首件优惠策略维护',
      permission: 'discount.policy.manage'
    }
  }, {
    path: 'reach-number-list',
    name: 'reachNumberList',
    component: function component() {
      return import(/* webpackChunkName: "reach-number-list" */'@/views/promotionPolicyManage/reachNumberList/index.vue');
    },
    meta: {
      title: '达量优惠策略列表',
      permission: 'discount.policy.manage'
    }
  }, {
    path: 'reach-number-create',
    name: 'reachNumberCreate',
    hidden: true,
    component: function component() {
      return import(/* webpackChunkName: "reach-number-create" */'@/views/promotionPolicyManage/reachNumberList/create.vue');
    },
    meta: {
      title: '达量优惠策略维护',
      permission: 'discount.policy.manage'
    }
  }, {
    path: 'reach-giving-list',
    name: 'reachGivingList',
    component: function component() {
      return import(/* webpackChunkName: "reach-giving-list" */'@/views/promotionPolicyManage/reachGivingList/index.vue');
    },
    meta: {
      title: '满减优惠策略列表',
      permission: 'discount.policy.manage'
    }
  }, {
    path: 'reach-giving-create',
    name: 'reachGivingCreate',
    hidden: true,
    component: function component() {
      return import(/* webpackChunkName: "reach-giving-create" */'@/views/promotionPolicyManage/reachGivingList/create.vue');
    },
    meta: {
      title: '满减优惠策略维护',
      permission: 'discount.policy.manage'
    }
  }]
};