export default {
  name: 'LbRender',
  functional: true,
  props: {
    scope: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    render: {
      type: Function,
      default: new Function()
    }
  },
  render: function render(h, ctx) {
    return ctx.props.render ? ctx.props.render(h, ctx.props.scope) : '';
  }
};