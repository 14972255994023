import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
/*
 * @Author: 张中玉
 * @Date: 2021-03-15 14:22:45
 * @LastEditors: 张中玉
 * @LastEditTime: 2021-03-16 15:53:54
 * @Description: 
 */
var plugIn = {};
plugIn.install = function (Vue) {
  // 处理路由数据

  Vue.prototype.$checkAuth = function (code) {
    if (code) {
      return this.$store.state.userinfo.authorities.includes(code);
    } else {
      return false;
    }
  };
  // 数组去重

  Vue.GlobolMethod = function () {};
};
export default plugIn;