/*
 * @Author: ZZY
 * @Date: 2021-03-28 11:30:24
 * @LastEditTime: 2022-02-25 17:00:31
 * @LastEditors: zhangyonghui
 * @Description: In User Settings Edit
 * @FilePath: \vue-ui-init\vue-ui\src\utils\request.js
 */
import axios from 'axios';
import router from '@/router';
import store from "../store";
import { getToken } from "@/utils/auth";
import { removeToken } from "./auth";
var service = axios.create({
  withCredentials: false,
  timeout: 120000
});

/**
 * 请求拦截器
 */
service.interceptors.request.use(function (config) {
  config.headers['Content-Type'] = 'application/json';
  config.headers['Access-Control-Allow-Origin'] = '*';
  config.headers['Authorization'] = 'Bearer ' + getToken();
  config.headers['strict-transport-security'] = 'max-age=15552000';
  // config.headers.tenantid = sessionStorage.getItem("tenantId")
  // store.state.layout.loadingShow = true
  return config;
}, function (error) {
  // do something with request error
  return Promise.reject(error);
});

/**
 * 响应拦截器
 */
service.interceptors.response.use(function (response) {
  var res = response.data;
  // if( (response.status === 200 && res.code !== '200') || response.status !== 200  ){
  // }
  if (response.status === 200 && res.code !== '200' && res.code !== undefined || response.status !== 200) {
    // Notification({
    //   title: '提示',
    //   type: 'error',
    //   message: res.message
    // })
    if (res.code === '403') {
      router.push('/dashboard');
    }
    if (res.code === '401') {
      removeToken();
      sessionStorage.clear();
      window.localStorage.removeItem("Authorization");
      router.push('/login');
    }
  }
  return res;
  //这里可以根据返回来的data来筛选数据
  //如果res.data里有数据，则返回数据，否则提示接口无数据(用于检测接口BUG)
  // store.state.layout.loadingShow=false
}, function (error) {
  store.state.layout.loadingShow = false;
  // Notification({
  //   title: '提示',
  //   type: 'error',
  //   message: error.message
  // })
  if (error.response.status == 401) {
    removeToken();
    if (process.env.NODE_ENV == "production") {
      window.location.href = process.env.VUE_APP_BASE_URL;
    } else {
      router.push('/dashboard');
    }
  }
  return Promise.reject(error);
});
export default service;