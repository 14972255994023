import Layout from "@/layout";
export default {
  path: '/display',
  name: 'adisplays',
  component: Layout,
  redirect: '/display/display-audit',
  meta: {
    type: 0,
    only: true,
    title: '陈列活动人工审核',
    icon: 'el-icon-lock',
    permission: "column.result.handle"
  },
  children: [{
    path: 'display-audit',
    name: 'displayAudit',
    component: function component() {
      return import(/* webpackChunkName: "shop" */'@/views/display/audit/index.vue');
    },
    meta: {
      title: '人工陈列审核',
      permission: 'result.audit'
    }
  }, {
    path: 'display-appeal',
    component: function component() {
      return import(/* webpackChunkName: "shop" */'@/views/display/appeal/index.vue');
    },
    name: 'displayAppeal',
    meta: {
      title: 'AI陈列审核',
      permission: 'result.appeal'
    }
  }]
};