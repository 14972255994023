import Layout from "@/layout";
export default {
  path: '/permission',
  name: 'permission',
  component: Layout,
  redirect: '/permission/permission-infos',
  meta: {
    type: 0,
    only: true,
    title: '权限',
    icon: 'el-icon-lock',
    permission: "permission.manage"
  },
  children: [{
    path: 'permission-infos',
    name: 'permissionInfos',
    component: function component() {
      return import(/* webpackChunkName: "permission" */'@/views/permission/permissionInfos/index.vue');
    },
    meta: {
      title: '权限管理',
      permission: 'permission.infos'
    }
  }, {
    path: 'role.infos',
    component: function component() {
      return import(/* webpackChunkName: "permission" */'@/views/permission/roleInfos/index.vue');
    },
    name: 'roleInfos',
    meta: {
      title: '角色管理',
      permission: 'role.infos'
    }
  }, {
    path: 'admin-infos',
    component: function component() {
      return import(/* webpackChunkName: "permission" */'@/views/permission/adminInfos/index.vue');
    },
    name: 'adminInfos',
    meta: {
      title: '管理员',
      permission: 'admin.infos'
    }
  }]
};