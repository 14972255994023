import Layout from "@/layout";
export default {
  //仪表盘
  path: '/activity',
  name: 'activity',
  component: Layout,
  redirect: '/activity/index',
  meta: {
    type: 0,
    only: true,
    title: '活动管理',
    icon: 'el-icon-lock',
    permission: "activity.manage"
  },
  children: [{
    path: 'index',
    name: 'activityList',
    component: function component() {
      return import('@/views/activity/activityList/index.vue');
    },
    meta: {
      title: '活动列表',
      type: 0,
      permission: 'activity.infos'
    }
  }, {
    path: 'dealer-activity-create',
    component: function component() {
      return import(/* webpackChunkName: "activity-create" */'@/views/dealerActivity/createDealerActivity/index.vue');
    },
    name: 'dealerActivityCreate',
    meta: {
      title: '经销商活动创建',
      permission: 'activity.scan.create'
    }
  }, {
    path: 'activity-create',
    component: function component() {
      return import(/* webpackChunkName: "activity-create" */'@/views/activity/activityCreate/index.vue');
    },
    name: 'activityCreate',
    meta: {
      title: '扫码奖励活动创建',
      permission: 'activity.scan.create'
    }
  }, {
    path: 'activity-create-display',
    component: function component() {
      return import(/* webpackChunkName: "activity-create-display" */'@/views/activity/displayActivityCreate/index.vue');
    },
    name: 'displayActivityCreate',
    meta: {
      title: '商品陈列活动创建',
      permission: 'activity.column.create'
    }
  }, {
    path: 'activity-create-stores',
    component: function component() {
      return import(/* webpackChunkName: "activity-create" */'@/views/activity/storesActivityCreate/index.vue');
    },
    name: 'storesActivityCreate',
    meta: {
      title: '门店推销活动创建',
      permission: 'activity.shop.create'
    }
  }, {
    path: 'activity-create-clerk',
    component: function component() {
      return import(/* webpackChunkName: "activity-create" */'@/views/activity/clerkActivityCreate/index.vue');
    },
    name: 'clerkActivityCreate',
    meta: {
      title: '店员激励活动创建',
      permission: 'activity.stimulate.create'
    }
  }, {
    path: 'activity-trade-in',
    component: function component() {
      return import(/* webpackChunkName: "activity-create" */'@/views/activity/trade-in/index.vue');
    },
    name: 'tradeInActivityCreate',
    meta: {
      title: '一元换购活动创建',
      permission: 'activity.stimulate.create'
    }
  }, {
    path: 'activity-coupon-list',
    component: function component() {
      return import(/* webpackChunkName: "activity-create" */'@/views/activity/couponCodeList/index.vue');
    },
    name: 'couponCodeList',
    meta: {
      title: '券码核销列表页',
      permission: 'activity.verificate.list'
    }
  }, {
    path: 'activity-coupon-baseinfo-create',
    component: function component() {
      return import(/* webpackChunkName: "activity-create" */'@/views/activity/couponCodeBaseinfoCreate/index.vue');
    },
    name: 'couponCodeBaseinfoCreate',
    meta: {
      title: '券码核销创建',
      permission: 'activity.verificate.create'
    }
  }]
};