import _objectSpread from "/tmp/workspace/bcrm/oso-platform-front/ci_bcrm_oso-platform-front/src_code/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import "core-js/modules/es6.regexp.search";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getTableByPage } from "./api.js";
export default {
  props: {
    propTableHeader: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    searchParamsList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    apiUrl: {
      type: String,
      default: ""
    },
    apiMethod: {
      type: String,
      default: "post"
    },
    searchParams: {
      type: Object,
      default: function _default() {}
    },
    extraQueryData: {
      type: Object,
      default: function _default() {}
    },
    detailRouteName: {
      type: String,
      default: ""
    },
    defaultSearchData: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      tableData: [],
      defaultSearchParams: {},
      searchPageData: {
        pageNum: 1,
        pageSize: 10
      },
      statusOptions: [{
        label: "在用",
        value: "1"
      }, {
        label: "停用",
        value: "2"
      }],
      tableHeaders: [],
      totalCount: 0,
      tableLoading: false
    };
  },
  created: function created() {
    this.tableHeaders = this.propTableHeader.concat(this.tableHeaders);
    this.defaultSearchParams = JSON.parse(JSON.stringify(this.searchParams));
  },
  mounted: function mounted() {
    this.search();
  },
  methods: {
    getTableData: function getTableData(data) {
      var _this = this;
      this.searchPageData = _objectSpread(_objectSpread({}, this.searchPageData), data);
      this.tableLoading = true;
      getTableByPage(this.apiMethod, this.apiUrl, this.searchPageData, this.searchPageData).then(function (res) {
        if (res.code == "200" && res.data) {
          _this.tableData = res.data.list;
          _this.totalCount = res.data.total;
          _this.searchPageData.pageNum = res.data.pageNum;
        }
      }).finally(function () {
        _this.tableLoading = false;
      });
    },
    setStartLoading: function setStartLoading() {
      this.tableLoading = true;
    },
    setEndLoading: function setEndLoading() {
      this.tableLoading = false;
    },
    // 清空
    empty: function empty() {
      this.searchParams = this.defaultSearchParams;
    },
    // 搜索
    search: function search() {
      this.getTableData(this.searchParams);
    }
  }
};