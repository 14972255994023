var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex-box" }, [
    _c(
      "label",
      { staticClass: "label", style: { width: _vm.labelWidth + "px" } },
      [
        _vm.required
          ? _c("span", { staticClass: "item-required" }, [_vm._v("*")])
          : _vm._e(),
        _vm._v(" "),
        _c("span", { style: _vm.labelStyle }, [_vm._v(_vm._s(_vm.label))]),
      ]
    ),
    _vm._v(" "),
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.colon,
            expression: "colon",
          },
        ],
      },
      [_vm._v(":")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "label-content",
        class: [_vm.border ? "borderContent" : ""],
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }