/*
 * @Author: your name
 * @Date: 2021-01-18 14:36:50
 * @LastEditTime: 2022-01-20 16:23:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \新建文件夹\src\components\globalComponents.js
 */
import baseTablePage from "./baseTablePage/index.vue";
import SearchLabel from "./SearchLabel/index.vue";
import SearchForm from "./SearchForm/index.vue";
import MasterTableList from "./masterTableList/index.vue";
function plugin(Vue) {
  if (plugin.installed) {
    return;
  }
  Vue.component("BaseTablePage", baseTablePage);
  Vue.component("SearchLabel", SearchLabel);
  Vue.component("SearchForm", SearchForm);
  Vue.component("MasterTableList", MasterTableList);
}
export default plugin;