var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _vm.device === "mobile" && _vm.sidebar.opened
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("sidebar", { staticClass: "sidebar-container" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-container" },
        [
          _c("div", [
            _c(
              "div",
              { class: { "fixed-header": _vm.fixedHeader } },
              [
                _c("img", {
                  staticClass: "carlsberg-logo",
                  attrs: { src: require("../assets/logo.svg"), alt: "." },
                  on: { click: _vm.navigateToHomePage },
                }),
                _vm._v(" "),
                _c("navbar", { staticStyle: { width: "100%" } }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("app-main"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }