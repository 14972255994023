import _Message2 from "element-ui/lib/theme-chalk/message.css";
import "element-ui/lib/theme-chalk/base.css";
import _Message from "element-ui/lib/message";
import "core-js/modules/es6.function.name";
import router from "@/router";
import userApi from "@/api/user.js";
import pubTools from '@/utils/pub-tools';
import Vue from 'vue';
var state = {
  email: null,
  realName: null,
  username: null,
  tenantId: null,
  buCode: null,
  buCodeName: null,
  buCodeAliasName: null,
  authorities: [],
  //权限
  dataBase: [],
  choosedatabase: [],
  MenuType: 0,
  status: null,
  MenuPath: [],
  id: '',
  onLineStatus: true,
  reLoad: false,
  collectionId: null,
  collectionName: null,
  collections: [],
  userType: null,
  currentLevel: null,
  topLevel: null,
  name: null,
  phone: null
};
var defaultState = {
  authorities: []
};
var mutations = {
  setAuthorities: function setAuthorities(state, step) {
    state.authorities = step;
  },
  setReLoad: function setReLoad(state, step) {
    state.reLoad = step;
  },
  setName: function setName(state, step) {
    state.name = step;
  },
  setPhone: function setPhone(state, step) {
    state.phone = step;
  },
  setUserType: function setUserType(state, step) {
    state.userType = step;
  }
};
var actions = {
  // 获取用户信息
  getUserInfo: function getUserInfo(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      userApi.getPermissionListByToken().then(function (res) {
        if (res.data) {
          commit("setName", res.data.name);
          commit("setPhone", res.data.phone);
          commit("setAuthorities", res.data.permissionList);
          commit("setUserType", res.data.userType);
          commit("setReLoad", true);
          resolve(res.data);
        } else {
          localStorage.removeItem("Authorization");
          if (process.env.NODE_ENV == "production") {
            router.push("/login");
          } else {
            resolve(false);
          }
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 注销登录
  logout: function logout(_ref2) {
    var commit = _ref2.commit,
      state = _ref2.state;
    return new Promise(function (resolve, reject) {
      userApi.logout(state.token).then(function (res) {
        if (res.code == 200) {
          _Message.success('退出成功');
          resolve();
        }
      }).catch(function (error) {
        _Message.error('退出失败');
        reject(error);
      });
    });
  }
};
export default {
  namespaced: true,
  //用于解决不同模块命名冲突的问题。设置为true时，使用模块中的mutations、getters、actions时候，要加上模块名。
  state: state,
  mutations: mutations,
  actions: actions
};