/*
 * @Author: your name
 * @Date: 2021-08-23 15:20:53
 * @LastEditTime: 2022-01-06 18:20:06
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \CarlsbergOrderAutoManagementFront\src\utils\filters.js
 */
import moment from "moment";
export function datefilter(date) {
  return date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "";
}
export function nodeTypeFilter(data) {
  return data === 1 ? "Approval" : "CC";
}
export function keepTwoDecimal(data) {
  return data || data === 0 ? data.toFixed(2) : "";
}
export function discountPoolTypeFilter(data) {
  var result = "";
  switch (data) {
    case 1:
      result = "不应用";
      break;
    case 2:
      result = "自动应用";
      break;
    case 3:
      result = "手动应用";
      break;
    default:
      result = "--";
      break;
  }
  return result;
}