//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Navbar, Sidebar, AppMain, TagsView } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
export default {
  name: "Layout",
  components: {
    Navbar: Navbar,
    Sidebar: Sidebar,
    AppMain: AppMain,
    TagsView: TagsView
  },
  mixins: [ResizeMixin],
  data: function data() {
    return {
      title: "联蔚云码",
      menuOpeneds: []
    };
  },
  computed: {
    sidebar: function sidebar() {
      return this.$store.state.app.sidebar;
    },
    device: function device() {
      return this.$store.state.app.device;
    },
    fixedHeader: function fixedHeader() {
      return this.$store.state.settings.fixedHeader;
    },
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile"
      };
    }
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", {
        withoutAnimation: false
      });
    },
    gotoOtherProduct: function gotoOtherProduct(url) {
      if (url.length > 15) {
        window.location.href = url;
      } else {
        window.location.reload();
      }
    },
    navigateToHomePage: function navigateToHomePage() {
      this.$router.push('/');
    }
  },
  mounted: function mounted() {
    var _this = this;
    document.addEventListener("click", function (e) {
      if (_this.$refs.menuTitle && _this.$refs.menuTitle.$el && !_this.$refs.menuTitle.$el.contains(e.target)) {
        _this.menuOpeneds = [];
      }
    });
  }
};