import Layout from "@/layout";
export default {
  path: '/user',
  name: 'user',
  component: Layout,
  redirect: '/user/system-user-info',
  meta: {
    type: 0,
    only: true,
    title: '用户管理',
    icon: 'el-icon-lock',
    permission: "user.manage"
  },
  children: [{
    path: 'system-user-info',
    name: 'systemUserInfo',
    component: function component() {
      return import(/* webpackChunkName: "user" */'@/views/user/userInfos/systemUserInfo.vue');
    },
    meta: {
      title: '系统用户信息',
      permission: 'system.user.manage'
    }
  }, {
    path: 'dealer-user-info',
    name: 'dealerUserInfo',
    component: function component() {
      return import(/* webpackChunkName: "user" */'@/views/user/userInfos/dealerUserInfo.vue');
    },
    meta: {
      title: '经销商用户信息',
      permission: 'customer.user.manage'
    }
  }, {
    path: 'user-password',
    component: function component() {
      return import(/* webpackChunkName: "user" */'@/views/user/userPassword/index.vue');
    },
    name: 'userPassword',
    meta: {
      title: '密码管理',
      permission: 'user.infos'
    }
  }, {
    path: 'user-shop',
    component: function component() {
      return import(/* webpackChunkName: "user" */'@/views/user/userShop/index.vue');
    },
    name: 'userShop',
    meta: {
      title: '用户门店',
      permission: 'user.shop'
    }
  }]
};