var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _vm._v(" "),
      _c("div", { staticClass: "right-menu" }, [
        _c("div", { staticClass: "avatar-container" }, [
          _c(
            "div",
            {
              staticClass: "avatar-wrapper",
              on: { click: _vm.drawerOptionChnage },
            },
            [
              _c("img", {
                staticClass: "user-avatar",
                attrs: { src: require("../../assets/user.png"), alt: "." },
              }),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("div", { staticClass: "user-name" }, [
            _vm._v(_vm._s(_vm.$store.state.userinfo.name)),
          ]),
          _vm._v(" "),
          _vm.isShowOrderMsg
            ? _c("em", {
                class:
                  _vm.isHaveNewOrderMsg || _vm.isHaveNewFinancialMsg
                    ? "el-icon-bell nav-icon _new"
                    : "el-icon-bell nav-icon",
                on: {
                  click: function ($event) {
                    return _vm.touchOrderMsgDrawer([true, 1])
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("DrawerUserOptions", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.drawerFlage,
                expression: "drawerFlage",
              },
            ],
            ref: "DrawerUserOptions",
            attrs: { "user-info": _vm.userInfo },
            on: { hidenEvent: _vm.drawerFlageChange },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }