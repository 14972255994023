import Layout from "@/layout";
export default {
  path: '/system',
  name: 'system',
  component: Layout,
  redirect: '/system/log-infos',
  meta: {
    type: 0,
    only: true,
    title: '系统功能',
    icon: 'el-icon-lock',
    permission: "system_function.manage"
  },
  children: [{
    path: 'es-logs',
    component: function component() {
      return import(/* webpackChunkName: "system" */'@/views/system/esLogs/index.vue');
    },
    name: 'esLogs',
    meta: {
      title: '全量ES日志',
      permission: 'message.warnning'
    }
  }, {
    path: 'order-logs',
    component: function component() {
      return import(/* webpackChunkName: "system" */'@/views/system/orderLogs/index.vue');
    },
    name: 'orderLogs',
    meta: {
      title: '全量订单流水',
      permission: 'message.warnning'
    }
  }, {
    path: 'activity-logs',
    component: function component() {
      return import(/* webpackChunkName: "system" */'@/views/system/activityLogs/index.vue');
    },
    name: 'activityLogs',
    meta: {
      title: '全量活动流水信息',
      permission: 'message.warnning'
    }
  }]
};