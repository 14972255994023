import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import _objectSpread from "/tmp/workspace/bcrm/oso-platform-front/ci_bcrm_oso-platform-front/src_code/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import "core-js/modules/web.dom.iterable";
/*
 * @Author: your name
 * @Date: 2020-12-28 10:52:00
 * @LastEditTime: 2022-02-25 17:33:30
 * @LastEditors: zhangyonghui
 * @Description: In User Settings Edit
 * @FilePath: \pt-cloud-ui\src\store\modules\permission.js
 */
import { asyncRoutes, constantRoutes } from '@/router';

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, authorityList) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);
    if (tmp.children) {
      tmp.children = filterAsyncRoutes(tmp.children, authorityList);
    }
    res.push(tmp);
  });
  return res;
}
var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, authorityList) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      var accessedRoutes = filterAsyncRoutes(asyncRoutes, authorityList);
      for (var i in accessedRoutes) {
        accessedRoutes[i].children = accessedRoutes[i].children.filter(function (item) {
          if (Array.isArray(item.meta.permission)) {
            var num = 0;
            for (var _i = 0; _i < item.meta.permission.length; _i++) {
              if (authorityList.includes(item.meta.permission[_i])) {
                num += 1;
              }
            }
            return num != 0;
          } else {
            return authorityList.includes(item.meta.permission) || item.meta.permission === 'connext';
          }
        });
      }
      var filterAccessedRoutes = accessedRoutes.filter(function (item) {
        return item.children.length !== 0;
      });
      filterAccessedRoutes.push({
        path: '*',
        redirect: '/404',
        hidden: true
      });
      commit('SET_ROUTES', filterAccessedRoutes);
      resolve(filterAccessedRoutes);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};