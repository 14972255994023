import Layout from "@/layout";
export default {
  //仪表盘
  path: '/report',
  name: 'report',
  component: Layout,
  redirect: '/report/index',
  meta: {
    type: 0,
    only: true,
    title: '报表导出',
    icon: 'el-icon-lock',
    permission: "report.form.infos"
  },
  children: [{
    path: 'index',
    name: 'reportList',
    component: function component() {
      return import('@/views/report/index.vue');
    },
    meta: {
      title: '报表导出列表',
      type: 0,
      permission: 'report.form.infos'
    }
  }]
};