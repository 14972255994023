import _objectSpread from "/tmp/workspace/bcrm/oso-platform-front/ci_bcrm_oso-platform-front/src_code/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import _variables from "@/styles/variables.scss";
export default {
  data: function data() {
    return {
      defaultOpeneds: ["/activity"]
    };
  },
  components: {
    SidebarItem: SidebarItem,
    Logo: Logo
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapGetters(["sidebar"])), mapState({
    choosedatabase: function choosedatabase(state) {
      return state.userinfo.choosedatabase;
    },
    MenuType: function MenuType(state) {
      return state.userinfo.MenuType;
    },
    authorities: function authorities(state) {
      return state.userinfo.authorities;
    },
    routes: function routes(state) {
      return state.permission.routes;
    }
  })), {}, {
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
        path = route.path;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  })
};