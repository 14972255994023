import Layout from "@/layout";
export default {
  // 订单管理
  path: '/order-manage',
  name: 'orderManage',
  component: Layout,
  redirect: '/order-manage/order-list',
  meta: {
    type: 0,
    only: true,
    title: '订单管理',
    icon: 'el-icon-lock',
    permission: "order.manage"
  },
  children: [{
    path: 'order-list',
    name: 'orderList',
    component: function component() {
      return import(/* webpackChunkName: "order-list" */'@/views/orderManage/orderList/index.vue');
    },
    meta: {
      title: '订单列表',
      permission: 'order.manage'
    }
  }, {
    path: 'order-detail',
    name: 'orderDetail',
    hidden: true,
    component: function component() {
      return import(/* webpackChunkName: "order-detail" */'@/views/orderManage/orderList/detail.vue');
    },
    meta: {
      title: '订单详情',
      permission: 'order.manage'
    }
  }, {
    path: 'order-statistical',
    name: 'orderStatistical',
    component: function component() {
      return import(/* webpackChunkName: "order-statistical" */'@/views/orderManage/orderStatistical/index.vue');
    },
    meta: {
      title: '订单统计',
      permission: 'order.manage'
    }
  }]
};