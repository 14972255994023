import Layout from "@/layout";
export default {
  path: '/shop',
  name: 'shop',
  component: Layout,
  redirect: '/shop/shop-info',
  meta: {
    type: 0,
    only: true,
    title: '门店信息',
    icon: 'el-icon-lock',
    permission: "shop.manage"
  },
  children: [{
    path: 'shop-info',
    name: 'shopInfo',
    component: function component() {
      return import(/* webpackChunkName: "shop" */'@/views/shop/shopInfo/index.vue');
    },
    meta: {
      title: '门店信息',
      permission: 'shop.info'
    }
  }, {
    path: 'staff-info',
    component: function component() {
      return import(/* webpackChunkName: "shop" */'@/views/shop/staffInfo/index.vue');
    },
    name: 'staffInfo',
    meta: {
      title: '员工信息',
      permission: 'staff.info'
    }
  }, {
    path: 'bu_user-info',
    component: function component() {
      return import(/* webpackChunkName: "shop" */'@/views/shop/bu_userInfo/index.vue');
    },
    name: 'bu_userInfo',
    meta: {
      title: 'bu人员信息',
      permission: 'bu_user.info'
    }
  }, {
    path: 'material-info',
    component: function component() {
      return import(/* webpackChunkName: "shop" */'@/views/shop/materialInfo/index.vue');
    },
    name: 'materialInfo',
    meta: {
      title: '礼品物料信息',
      permission: 'material.info'
    }
  }, {
    path: 'shop-appeal',
    component: function component() {
      return import(/* webpackChunkName: "shop" */'@/views/shop/shopAppeal/index.vue');
    },
    name: 'shopAppeal',
    meta: {
      title: '门店申诉',
      permission: 'material.info'
    }
  }, {
    path: 'shop-organization',
    component: function component() {
      return import(/* webpackChunkName: "shop" */'@/views/shop/shopOrganization/index.vue');
    },
    name: 'shopOrganization',
    meta: {
      title: '门店组织架构',
      permission: 'shop.organization'
    }
  }, {
    path: 'shop-sku',
    component: function component() {
      return import(/* webpackChunkName: "shop" */'@/views/shop/shopSku/index.vue');
    },
    name: 'shopSku',
    meta: {
      title: '门店sku信息',
      permission: 'shop.sku'
    }
  }, {
    path: 'employees-list',
    component: function component() {
      return import(/* webpackChunkName: "shop" */'@/views/shop/employeesList/index.vue');
    },
    name: 'employeesList',
    meta: {
      title: '员工列表',
      permission: 'shop.sku'
    }
  }, {
    path: 'guest-list',
    component: function component() {
      return import(/* webpackChunkName: "shop" */'@/views/shop/guestList/index.vue');
    },
    name: 'guestList',
    meta: {
      title: '客表列表',
      permission: 'shop.sku'
    }
  }, {
    path: 'guest-detail/:formNo',
    component: function component() {
      return import(/* webpackChunkName: "shop" */'@/views/shop/guestList/guestDetail.vue');
    },
    name: 'guestDetail',
    hidden: true,
    meta: {
      title: '客表详情',
      permission: 'shop.sku'
    }
  }]
};