import Layout from "@/layout";
export default {
  // 订单管理
  path: '/account-manage',
  name: 'accountManage',
  component: Layout,
  redirect: '/account-manage/account-list',
  meta: {
    type: 0,
    only: true,
    title: '账号管理',
    icon: 'el-icon-lock',
    permission: "sale.user.list"
  },
  children: [{
    path: 'account-list',
    name: 'accountList',
    component: function component() {
      return import(/* webpackChunkName: "account-list" */'@/views/accountManage/list.vue');
    },
    meta: {
      title: '账号列表',
      permission: 'sale.user.list'
    }
  }, {
    path: 'account-detail',
    name: 'accountDetail',
    hidden: true,
    component: function component() {
      return import(/* webpackChunkName: "account-detail" */'@/views/accountManage/detail.vue');
    },
    meta: {
      title: '账号详情',
      permission: 'sale.user.list'
    }
  }]
};