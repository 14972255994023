var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "changeUserInfo" }, [
        _c("div", { staticStyle: { "padding-top": "20px" } }, [
          _c("h4", [_vm._v("帐户信息")]),
          _vm._v(" "),
          _c("div", { staticClass: "infoType" }, [
            _c("div", { staticClass: "email" }, [
              _vm._v("用户名: " + _vm._s(_vm.name)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "email" }, [
              _vm._v("手机号: " + _vm._s(_vm.phone || "--")),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "control-wrap" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "text", size: "mini" },
                  on: { click: _vm.Logout },
                },
                [
                  _c("em", { staticClass: "el-icon-switch-button" }),
                  _vm._v("\n          注销登录\n        "),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "text", size: "mini" },
                  on: { click: _vm.modifyPassword },
                },
                [
                  _c("em", { staticClass: "el-icon-edit" }),
                  _vm._v("\n          修改密码\n        "),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "dialogLayer",
        on: { click: _vm.hideUserInfo },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isShowModifyPwdDialog,
            title: "修改密码",
            "show-close": false,
            width: "40%",
            "append-to-body": "",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "modifyPwdForm",
              attrs: { model: _vm.modifyPwdForm, "label-width": "120px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "旧密码：" } },
                [
                  _c("el-input", {
                    attrs: { type: "password", clearable: "" },
                    model: {
                      value: _vm.modifyPwdForm.originPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.modifyPwdForm, "originPassword", $$v)
                      },
                      expression: "modifyPwdForm.originPassword",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "新密码：" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "password",
                      clearable: "",
                      "show-password": "",
                    },
                    model: {
                      value: _vm.modifyPwdForm.newPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.modifyPwdForm, "newPassword", $$v)
                      },
                      expression: "modifyPwdForm.newPassword",
                    },
                  }),
                  _vm._v(" "),
                  _c("password-strength", {
                    attrs: {
                      password: _vm.modifyPwdForm.newPassword,
                      tips: "密码需要包含大写字母、小写字母、数字、特殊字符，长度在12位以上",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "重复密码：" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "password",
                      clearable: "",
                      "show-password": "",
                    },
                    model: {
                      value: _vm.modifyPwdForm.confirmPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.modifyPwdForm, "confirmPassword", $$v)
                      },
                      expression: "modifyPwdForm.confirmPassword",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "end",
                    "margin-bottom": "0",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.isShowModifyPwdDialog = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.submitPwdChange },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }