import { login as _login, logout as _logout, getInfo as _getInfo } from "@/api/user";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { resetRouter } from "@/router";
var getDefaultState = function getDefaultState() {
  return {
    token: getToken(),
    name: "",
    avatar: "",
    email: "",
    tenantId: "connext",
    auth: [],
    grantedApplications: [{
      name: "SaaS",
      url: "http://139.224.117.57/platform/",
      icon: "icon-saas",
      themeColor: "#009688",
      granted: true
    }, {
      name: "MMC",
      url: "http://139.224.117.57/mmc/",
      icon: "icon-management",
      themeColor: "#D32F2F",
      granted: true
    }, {
      name: "TENANT",
      url: "http://139.224.117.57/tenant/",
      icon: "icon-bag",
      themeColor: "#00796B",
      granted: true
    }, {
      name: "PT",
      url: "/pt/",
      icon: "icon-shujuzhuisu",
      themeColor: "#42b983",
      granted: true
    }]
  };
};
var state = getDefaultState();
var mutations = {
  RESET_STATE: function RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_TENANTID: function SET_TENANTID(state, tenantId) {
    state.tenantId = tenantId;
  },
  SET_EMAIL: function SET_EMAIL(state, email) {
    state.email = email;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AUTH: function SET_AUTH(state, auth) {
    state.auth = auth;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_APPLICATIONS: function SET_APPLICATIONS(state, grantedApplications) {
    state.grantedApplications = grantedApplications;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var username = userInfo.username,
      password = userInfo.password;
    return new Promise(function (resolve, reject) {
      _login({
        username: username.trim(),
        password: password
      }).then(function (response) {
        var data = response.data;
        commit("SET_TOKEN", data.token);
        setToken(data.token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref2) {
    var commit = _ref2.commit,
      state = _ref2.state;
    return new Promise(function (resolve, reject) {
      _getInfo().then(function (response) {
        if (!response.authorized) {
          reject("Verification failed, please Login again.");
        } else {
          commit("SET_NAME", response.userAuthInfo.username);
          commit("SET_EMAIL", response.userAuthInfo.email);
          commit("SET_TENANTID", response.userAuthInfo.tenantId);
          commit("SET_AUTH", response.userAuthInfo.authorities);
          commit("SET_APPLICATIONS", response.userAuthInfo.grantedApplications);
        }
        // const { avatar } = data
        // commit('SET_AVATAR', avatar)
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
      state = _ref3.state;
    return new Promise(function (resolve, reject) {
      _logout(state.token).then(function () {
        removeToken(); // must remove  token  first
        resetRouter();
        commit("RESET_STATE");
        sessionStorage.clear();
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      removeToken(); // must remove  token  first
      commit("RESET_STATE");
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};