export var tableHeaderStyle = function tableHeaderStyle(_ref) {
  var row = _ref.row,
    column = _ref.column,
    rowIndex = _ref.rowIndex,
    columnIndex = _ref.columnIndex;
  if (rowIndex === 0) {
    return "\n          background-color: #e5e5e5 !important;\n          color: #000;\n          border-right:1px solid #fff;\n          padding: 8px 0px;\n          ";
  }
};
export var tableHeaderStyleChildren = function tableHeaderStyleChildren(_ref2) {
  var row = _ref2.row,
    column = _ref2.column,
    rowIndex = _ref2.rowIndex,
    columnIndex = _ref2.columnIndex;
  if (rowIndex === 0) {
    return "\n          background-color: #edf0ec !important;\n          color: #436948;\n          border-right:1px solid #eceef4;\n          padding:5px 0px;\n          ";
  }
};
export var rowStyle = function rowStyle(_ref3) {
  var row = _ref3.row,
    rowIndex = _ref3.rowIndex;
  return {
    padding: "5px 0px !important",
    "font-size": "14px"
  };
};
export var rowStyleChild = function rowStyleChild(_ref4) {
  var row = _ref4.row,
    rowIndex = _ref4.rowIndex;
  return {
    padding: "5px 0px !important",
    "font-size": "12px",
    color: "#909399"
  };
};
export var getRowClass = function getRowClass(row, rowIndex) {
  var data = row.row.list;
  var res = [];
  if (data && data.length > 0) {
    res.push("row-expand-has");
    return res;
  } else {
    res.push("row-expand-unhas");
    return res;
  }
};