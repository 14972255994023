/*
 * @Author: your name
 * @Date: 2021-01-12 19:06:25
 * @LastEditTime: 2021-01-12 19:07:49
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \pt-cloud-ui\src\store\modules\settings.js
 */
import variables from '@/styles/element-variables.scss';
import defaultSettings from '@/settings';
var tagsView = defaultSettings.tagsView,
  fixedHeader = defaultSettings.fixedHeader,
  sidebarLogo = defaultSettings.sidebarLogo,
  uniqueOpened = defaultSettings.uniqueOpened,
  showFooter = defaultSettings.showFooter,
  footerTxt = defaultSettings.footerTxt,
  caseNumber = defaultSettings.caseNumber;
var state = {
  theme: variables.theme,
  showSettings: false,
  tagsView: tagsView,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  uniqueOpened: uniqueOpened,
  showFooter: showFooter,
  footerTxt: footerTxt,
  caseNumber: caseNumber,
  themeCluster: []
};
var mutations = {
  CHANGE_SETTING: function CHANGE_SETTING(state, _ref) {
    var key = _ref.key,
      value = _ref.value;
    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  }
};
var actions = {
  changeSetting: function changeSetting(_ref2, data) {
    var commit = _ref2.commit;
    commit('CHANGE_SETTING', data);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};