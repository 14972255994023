/*
 * @Author: 张中玉
 * @Date: 2021-04-25 10:53:37
 * @LastEditors: 张中玉
 * @LastEditTime: 2021-05-26 15:30:10
 * @Description: 
 */
// import OrderApi from "../../../src//views/order/api/orderApi";
var state = {
  policyValue: '',
  selectProductData: [],
  orderId: '',
  carMenu: false,
  isPromotion: null,
  checked: false,
  shippingCode: '',
  promotionPolicyItem: [],
  selfDeliveryMethodData: 2,
  shippingTime: ''
};
var mutations = {
  setPolicyValue: function setPolicyValue(state, step) {
    state.policyValue = step;
  },
  setSelectProductData: function setSelectProductData(state, step) {
    state.selectProductData = step;
  },
  setPromotionStatus: function setPromotionStatus(state, step) {
    state.isPromotion = step;
  },
  setOrderId: function setOrderId(state, step) {
    sessionStorage.setItem('orderId', step);
    state.orderId = step;
  },
  setCarMenu: function setCarMenu(state, step) {
    state.carMenu = step;
  },
  setChecked: function setChecked(state, step) {
    state.checked = step;
  },
  setShippingCode: function setShippingCode(state, step) {
    state.shippingCode = step;
  },
  setPromotionPolicyItem: function setPromotionPolicyItem(state, step) {
    state.promotionPolicyItem = step;
  },
  setDeliveryMethod: function setDeliveryMethod(state, step) {
    state.selfDeliveryMethodData = step;
  },
  setShippingTime: function setShippingTime(state, step) {
    state.shippingTime = step;
  }
};
var actions = {};
export default {
  namespaced: true,
  //用于解决不同模块命名冲突的问题。设置为true时，使用模块中的mutations、getters、actions时候，要加上模块名。
  state: state,
  mutations: mutations,
  actions: actions
};