import _objectSpread from "/tmp/workspace/bcrm/oso-platform-front/ci_bcrm_oso-platform-front/src_code/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import tableRender from "./table-render";
export default {
  name: "BaseTablePage",
  components: {
    tableRender: tableRender
  },
  filters: {
    formatters: function formatters(val, format) {
      if (typeof format === "function") {
        return format(val);
      } else return val;
    },
    statusFilter: function statusFilter(val) {
      return val === "1" ? "在用" : "停用";
    }
  },
  props: {
    // 是否使用单选
    isRadio: {
      type: Boolean,
      default: false
    },
    defaultRadio: {
      type: String,
      default: ""
    },
    // 是否翻页选中
    reserveSelection: {
      type: Boolean,
      default: false
    },
    // 绑定rowkey
    rowKey: {
      type: Function,
      default: function _default() {
        return null;
      }
    },
    tableClass: {
      type: String,
      default: ""
    },
    // 表格是否使用选择列
    isShowselection: {
      type: Boolean,
      default: false
    },
    defaultSelectData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 搜索数据
    searchData: {
      type: Object,
      default: function _default() {
        return {
          page: 0,
          size: 10
        };
      }
    },
    selectStatus: {
      type: Function,
      default: function _default() {
        return true;
      }
    },
    searchSizes: {
      type: Array,
      default: function _default() {
        return [10, 20, 30, 40, 50];
      }
    },
    // 是否显示分页
    isShowPagination: {
      type: Boolean,
      default: true
    },
    // 表格数据
    tableData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    rowClassControl: {
      type: Function,
      default: function _default() {
        return "";
      }
    },
    cellClassControl: {
      type: Function,
      default: function _default() {
        return "";
      }
    },
    spanMethodControl: {
      type: Function,
      default: function _default() {
        return "";
      }
    },
    // 分页数据
    pages: {
      type: Object,
      default: function _default() {
        return {
          page: 0,
          size: 20
        };
      }
    },
    // totalCount
    totalCount: {
      type: Number,
      default: 0
    },
    // table循环数据
    tableHeader: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
    // 表格选择数据
    // multipleSelection: {
    //   type: Array,
    //   default: function() {
    //     return []
    //   }
    // }
  },
  data: function data() {
    return {
      radio: ""
    };
  },
  watch: {
    defaultSelectData: {
      handler: function handler(newValue, oldValue) {
        var _this = this;
        var _loop = function _loop(i) {
          _this.$nextTick(function () {
            _this.$refs.multipleTable.toggleRowSelection(newValue[i], true);
          });
        };
        for (var i = 0; i < newValue.length; i++) {
          _loop(i);
        }
      },
      deep: true
    },
    defaultRadio: function defaultRadio(newValue, oldValue) {
      this.radio = newValue;
    }
  },
  methods: {
    // 列选择时的回调
    handleSelectionChange: function handleSelectionChange(val) {
      this.$emit("multipleSelection", val);
    },
    btnTooltip: function btnTooltip(o) {
      if (o.icon.includes("bianji")) {
        return "查看";
      } else if (o.icon.includes("dizhi")) {
        return "位置信息";
      } else if (o.icon.includes("shanchu")) {
        return "删除数据";
      } else if (o.icon.includes("shenhe")) {
        return "审核";
      } else if (o.icon.includes("wancheng")) {
        return "改变状态";
      } else if (o.icon.includes("zhuanxierizhi")) {
        return "授权";
      } else if (o.icon.includes("daoru")) {
        return "下载";
      } else if (o.icon.includes("kaishi")) {
        return "下载";
      } else if (o.icon.includes("chakan")) {
        return "查看";
      } else if (o.icon.includes("cha")) {
        return "停用";
      } else if (o.icon.includes("RectangleCopy8")) {
        return "品牌配置";
      } else {
        return false;
      }
    },
    filterHandler: function filterHandler(filters) {
      this.$emit("filter-events", filters);
    },
    setRowKey: function setRowKey(row) {
      return this.rowKey(row);
    },
    // 禁用
    selectInit: function selectInit(row, index) {
      return this.selectStatus(row, index);
    },
    clearSelection: function clearSelection() {
      this.$refs.multipleTable.clearSelection();
    },
    SizeChange: function SizeChange(val) {
      this.searchData.size = val;
      var data = _objectSpread(_objectSpread({}, this.searchData), {}, {
        pageSize: 0
      });
      this.$emit("getTableData", data);
    },
    CurrentChange: function CurrentChange(page) {
      var data = _objectSpread(_objectSpread({}, this.searchData), {}, {
        pageNum: page
      });
      this.$emit("getTableData", data);
    },
    getRadioCurrentRow: function getRadioCurrentRow(data) {
      this.$emit("getRadioCurrentRow", data);
    },
    cellClass: function cellClass(data) {
      return this.cellClassControl(data);
    },
    spanMethod: function spanMethod(data) {
      return this.spanMethodControl(data);
    },
    rowClass: function rowClass(data) {
      return this.rowClassControl(data);
    },
    clearRadio: function clearRadio() {
      this.radio = "";
    }
  }
};