exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"menuText": "#fff",
	"menuActiveText": "#f5222d",
	"subMenuActiveText": "#f5222d",
	"menuBg": "#213e37",
	"menuHover": "#fee9ea",
	"subMenuBg": "#fff",
	"subMenuHover": "#fee9ea",
	"sideBarWidth": "200px",
	"navbarHeight": "60px"
};