var state = {
  loginState: false
};
var mutations = {
  loginState: function loginState(state, step) {
    state.loginState = step;
  }
};
var actions = {};
export default {
  namespaced: true,
  //用于解决不同模块命名冲突的问题。设置为true时，使用模块中的mutations、getters、actions时候，要加上模块名。
  state: state,
  mutations: mutations,
  actions: actions
};