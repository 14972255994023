/*
 * @Author: your name
 * @Date: 2021-01-12 18:23:16
 * @LastEditTime: 2021-01-13 10:40:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \pt-cloud-ui\src\store\modules\layout.js
 */
var state = {
  MenuDown: false,
  MenuState: false,
  UserMenuState: false,
  SettingMenu: false
};
var mutations = {
  changeMenuDownState: function changeMenuDownState(state) {
    state.MenuDown = !state.MenuDown;
  },
  changeMenuState: function changeMenuState(state) {
    state.MenuState = !state.MenuState;
  },
  changeUserMenuState: function changeUserMenuState(state) {
    state.UserMenuState = !state.UserMenuState;
  },
  changeSettingMenuState: function changeSettingMenuState(state) {
    state.SettingMenu = !state.SettingMenu;
  },
  openMenu: function openMenu(state) {
    state.MenuState = true;
  },
  openUserMenu: function openUserMenu(state) {
    state.UserMenuState = true;
  },
  closeMenu: function closeMenu(state) {
    state.MenuState = false;
  },
  closeUserMenu: function closeUserMenu(state) {
    state.UserMenuState = false;
  },
  closeSettingMenu: function closeSettingMenu(state) {
    state.SettingMenu = false;
  },
  changeTheme: function changeTheme(state, step) {
    state.settings.theme = step;
  },
  closeAllMenu: function closeAllMenu(state) {
    state.MenuState = false;
    state.UserMenuState = false;
  }
};
var actions = {};
export default {
  namespaced: true,
  //用于解决不同模块命名冲突的问题。设置为true时，使用模块中的mutations、getters、actions时候，要加上模块名。
  state: state,
  mutations: mutations,
  actions: actions
};