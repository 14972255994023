var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "password-strength" }, [
    _c("div", { staticClass: "password-strength-tip" }, [
      _vm._v("\n    密码强度："),
      _c("strong", [
        _vm._v(
          _vm._s(_vm.strength === 4 ? "高" : _vm.strength === 3 ? "中" : "低")
        ),
      ]),
      _vm._v("（" + _vm._s(_vm.tips) + "）\n  "),
    ]),
    _vm._v(" "),
    _c("ul", { staticClass: "password-strength-bar" }, [
      _c("li", { class: { active: _vm.strength >= 2 } }),
      _vm._v(" "),
      _c("li", { class: { active: _vm.strength >= 3 } }),
      _vm._v(" "),
      _c("li", { class: { active: _vm.strength === 4 } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }