import Layout from "@/layout";
export default {
  path: '/awardTemplate',
  name: 'awardTemplate',
  component: Layout,
  redirect: '/awardTemplate/index',
  meta: {
    type: 0,
    only: true,
    title: '奖励模板管理',
    icon: 'el-icon-lock',
    permission: "award_template.manage"
  },
  children: [{
    path: 'index',
    name: 'awardTemplateList',
    component: function component() {
      return import(/* webpackChunkName: "award-template" */'@/views/awardTemplate/awardTemplateList/index.vue');
    },
    meta: {
      title: '模板列表',
      permission: 'award_template.lists'
    }
  }, {
    path: 'award-template-create',
    component: function component() {
      return import(/* webpackChunkName: "award-template" */'@/views/awardTemplate/awardTemplateCreate/index.vue');
    },
    name: 'awardTemplateCreate',
    meta: {
      title: '创建奖励模板',
      permission: 'award_template.create'
    }
  }]
};