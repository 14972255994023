import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import store from "@/store";
/*
权限控制说明

绑定方式：v-permission
参数：
    v-permission="cdp" 无视权限，直接访问
    v-permission="权限表对应的值" 根据权限，按权限加载
*/

export default (function (Vue) {
  Vue.directive("permission", {
    inserted: function inserted(el, binding) {
      var authList = store.state.userinfo.authorities;
      if (!authList.includes(binding.value) && binding.value != "cdp") {
        el.remove();
      }
    }
  });
});